import {CITY_RESULT_ARE_LOADING, CITY_RESULT_HAVE_ERROR, CITY_RESULT_FETCH_DATA_SUCCESS} from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
  };
  
  export function CityResultReducer (state = initialState, action) {
    switch (action.type) {
        case CITY_RESULT_HAVE_ERROR:
            return {
                ...state,
                error:action.hasError,
                loading:false,
            }
    
        case CITY_RESULT_ARE_LOADING:
           return {
            ...state,
            loading:action.isLoading
        }
    
        case CITY_RESULT_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data:action.cityResult,
                loading:false,
            }

        default:
            return state;
    }
}
