import { INPUT_VALUE_FETCH_DATA_SUCCESS, INPUT_VALUE_HAVE_ERROR, INPUT_VALUE_ARE_LOADING } from '../actionType';

export function searchValueAction(action, payload) {
    switch (action) {
        case INPUT_VALUE_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };
    
        case INPUT_VALUE_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };
    
        case INPUT_VALUE_FETCH_DATA_SUCCESS:
            return {
                type: action,
                inputSearch: payload,
            };

        default:
            return;
    }
}

export function searchValue(searchValue) {
    return (dispatch) => {
        try {
            return  dispatch(searchValueAction(INPUT_VALUE_FETCH_DATA_SUCCESS, searchValue));
        } catch (error) {
            dispatch(searchValueAction(INPUT_VALUE_HAVE_ERROR, true));
        }
    }
}
