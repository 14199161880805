import {CHILDMENU_ARE_LOADING, CHILDMENU_FETCH_DATA_SUCCESS, CHILDMENU_HAVE_ERROR} from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
  };
  
  export function ChildmenuReducer (state = initialState, action) {
    switch (action.type) {
        case CHILDMENU_HAVE_ERROR:
            return {
                ...state,
                error:action.hasError,
                loading:false,
            }
    
        case CHILDMENU_ARE_LOADING:
           return {
            ...state,
            loading:action.isLoading
        }
    
        case CHILDMENU_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data:action.childmenu,
                loading:false,
            }

        default:
            return state;
    }
}

