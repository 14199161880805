import { SETTING_ARE_LOADING, SETTING_FETCH_DATA_SUCCESS, SETTING_HAVE_ERROR } from '../actionType';

const cdnUrl = localStorage.getItem('SandeshCDN')
const defaultAssets = { 21: { meta: 'Media CDN', value: cdnUrl || 'https://assets.sandesh.com/' } };
const initialState = {
    data: defaultAssets,
    loading: true,
    error: []
};

export function SettingReducer(state = initialState, action) {
    switch (action.type) {
        case SETTING_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case SETTING_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }

        case SETTING_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.Setting,
                loading: false,
            }

        default:
            return state;
    }
}
