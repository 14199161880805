import {TITHI_ARE_LOADING, TITHI_FETCH_DATA_SUCCESS, TITHI_HAVE_ERROR} from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function TithiAction(action, payload) {
    switch (action) {
        case TITHI_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };
    
        case TITHI_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };
    
        case TITHI_FETCH_DATA_SUCCESS:
            return {
                type: action,
                Tithi: payload,
            };

        default:
            return;
    }
}

export function tithiFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(TithiAction(TITHI_ARE_LOADING, true));
                Get(HttpService.tithi).then((result) => {
                    dispatch(TithiAction(TITHI_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(TithiAction(TITHI_HAVE_ERROR, true));
        }
    }
}


