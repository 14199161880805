import { FOOTER_ARE_LOADING, FOOTER_FETCH_DATA_SUCCESS, FOOTER_HAVE_ERROR } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function FootertAction(action, payload) {
    switch (action) {
        case FOOTER_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };
    
        case FOOTER_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };
    
        case FOOTER_FETCH_DATA_SUCCESS:
            return {
                type: action,
                Footer: payload,
            };

        default:
            return;
    }
}

export function footerFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(FootertAction(FOOTER_ARE_LOADING, true));
                Get(HttpService.footer).then((result) => {
                    dispatch(FootertAction(FOOTER_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(FootertAction(FOOTER_HAVE_ERROR, true));
        }
    }
}
