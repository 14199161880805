import React from 'react'
import Astrology from './Astrology'
// import Horoscope from './Horoscope'
// import Panchang from './Panchang'
// import Zodiac from './Zodiac'



const Header = () => {
    // const [activeTab, setActiveTab] = React.useState('');


    return (
        <>
                                  

            <div className='contanier'>
               
                <div className='row'>

                    <div className="col-md-12 my-3 gujarat-responsive-space-sm">
                        {/* <ul className="nav nav-tabs-astro-component margin-bottom-fu mb-2"> */}
                            {/* <li className="nav-item border-right"> */}
                                {/* <a className="nav-link-astro-component active" data-toggle="tab" href="#astrology">Astrology</a> */}
                            {/* </li> */}
                            {/* <li className="nav-item border-right">
                                <a className="nav-link-astro-component" data-toggle="tab" href="#horoscope">Horoscope</a>
                            </li>
                            <li className="nav-item border-right">
                                <a className="nav-link-astro-component" data-toggle="tab" href="#panchang">Panchang</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link-astro-component" data-toggle="tab" href="#zodiac">Zodiac</a>
                            </li> */}
                        {/* </ul> */}
                        {/* <hr className='m-0' /> */}
                        <div className="tab-content">
                            <div className="tab-pane active" id="astrology">
                                <Astrology />
                            </div>
                            {/* <div className="tab-pane fade" id="horoscope">
                                <Horoscope />
                            </div>
                            <div className="tab-pane fade" id="panchang">
                                <Panchang />
                            </div>
                            <div className="tab-pane fade" id="zodiac">
                                <Zodiac />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
