import { POST_FETCH_DATA_SUCCESS, POST_HAVE_ERROR, POST_ARE_LOADING } from '../actionType';

import HttpService from '../../utils/httpService';
import { Post } from '../../services/api.service'

export function PostAction(action, payload) {
    switch (action) {
        case POST_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case POST_ARE_LOADING:
            return {
                type: action,
                isLoading: payload,
                Post: null,
            };

        case POST_FETCH_DATA_SUCCESS:
            return {
                type: action,
                Post: payload,
            };

        default:
            return;
    }
}

export function PostFetchData(url) {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(PostAction(POST_ARE_LOADING, true));
                Post(`${HttpService.post}`, { url: url }).then((result) => {
                    dispatch(PostAction(POST_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(PostAction(POST_HAVE_ERROR, true));
        }
    }
}
