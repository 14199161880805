import { COMMENT_FETCH_DATA_SUCCESS, COMMENT_HAVE_ERROR, COMMENT_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Post } from '../../services/api.service'

export function CommentAction(action, payload) {
    switch (action) {
        case COMMENT_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case COMMENT_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case COMMENT_FETCH_DATA_SUCCESS:
            return {
                type: action,
                data: payload
            };

        default:
            return;
    }
}


export function CommentFetchData(id) {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(CommentAction(COMMENT_ARE_LOADING, true));
                Post(HttpService.comment, { id: id }).then((result) => {
                    dispatch(CommentAction(COMMENT_FETCH_DATA_SUCCESS, result?.data));
                    resolve(result?.data);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(CommentAction(COMMENT_HAVE_ERROR, true));
        }
    }
}
