import { ENTERTAINMENT_FETCH_DATA_SUCCESS, ENTERTAINMENT_HAVE_ERROR, ENTERTAINMENT_ARE_LOADING } from '../actionType';

import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function EntertainmentAction(action, payload) {
    switch (action) {
        case ENTERTAINMENT_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case ENTERTAINMENT_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case ENTERTAINMENT_FETCH_DATA_SUCCESS:
            return {
                type: action,
                Category: payload,
            };

        default:
            return;
    }
}

export function EntertainmentFetchData(type = "", payload = {}, params = '') {
    return (dispatch) => {
        let myurl = HttpService.category + params
        try {
            return new Promise((resolve, reject) => {
                dispatch(EntertainmentAction(ENTERTAINMENT_ARE_LOADING, true));
                Get(myurl).then((result) => {
                    const obje = {
                        ...result,
                        ...result?.data,
                        count: result?.count,
                        name: result?.name,
                        message: result?.message,
                    }
                    dispatch(EntertainmentAction(ENTERTAINMENT_FETCH_DATA_SUCCESS, obje));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(EntertainmentAction(ENTERTAINMENT_HAVE_ERROR, true));
        }
    }
}