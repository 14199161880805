/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { sharecount } from '../../redux/actions/commonAction';
import ReactGA from "react-ga4";

const Share = (props) => {
    const urlshares = 'https://' + window.location.hostname + '/' + props.url;
    const urlshare = 'https://' + window.location.hostname + '/' + props.url + '\r\n \r\n';
    const { 21: assetbaseUrl, 26: sharemsg } = useSelector((state) => state?.SettingReducer?.data);
    const media = assetbaseUrl?.value + props.media + '?resize=1200,630';
    // const title = props.title + '\r\n \r\n' + sharemsg?.value + '\r\n \r\n';
    const title = props.title + '\r\n';
    const ShareValue = sharemsg?.value + '\r\n \r\n';
    // const copytitle = props.title + '\r\n' + window.location.href + '\r\n \r\n' + sharemsg?.value;

    const dispatch = useDispatch();
    const returnfalse = async (name) => {
        document.getElementById(props.id).classList.add('d-none');
        await dispatch(sharecount(name, props?.dataid));
        // document.stopPropagation();
        return false;
    }

    function returnStop1(e) {
        document.getElementById(props.id).classList.add('d-none');
        dispatch(sharecount('fb', props?.dataid));
        ReactGA.event({ category: 'Share', action: 'Facebook', label: urlshare });
        e.stopPropagation();
        return false;
    }

    function returnStop2(e) {
        document.getElementById(props.id).classList.add('d-none');
        dispatch(sharecount('tw', props?.dataid));
        ReactGA.event({ category: 'Share', action: 'Twitter', label: urlshare });
        e.stopPropagation();
        return false;
    }

    function returnStop3(e) {
        document.getElementById(props.id).classList.add('d-none');
        dispatch(sharecount('wa', props?.dataid));
        ReactGA.event({ category: 'Share', action: 'Whatsapp', label: urlshare });
        e.stopPropagation();
        return false;
    }
    function returnStop4(e) {
        document.getElementById(props.id).classList.add('d-none');
        // dispatch(sharecount('wa', props?.dataid));
        ReactGA.event({ category: 'Share', action: 'Email', label: urlshare });
        e.stopPropagation();
        // e.preventDefault();
        return false;
    }

    const copy = (e) => {
        returnfalse('copy');
        ReactGA.event({ category: 'Share', action: 'Copy', label: urlshare });
        var textArea = document.createElement("textarea");
        document.body.className += ' copied';

        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';

        textArea.value = urlshares;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');

            const node = document.createElement("span");
            const textnode = document.createTextNode("Link copied.");
            node.className = "copyMsg";
            node.setAttribute("id", "copyMsg");
            node.appendChild(textnode);
            document.getElementById('root').appendChild(node);
            setTimeout(() => {
                document.getElementById('copyMsg').outerHTML = '';
            }, 1000);
        } catch (err) {
        }

        document.getElementById(props.id).classList.add('d-none');

        document.body.removeChild(textArea);
        document.querySelector('body').classList.remove('copied');
        e.stopPropagation();
        return false;
    }

    return (
        <>
            <a id="dropdown" onClickCapture={returnStop1} onClick={() => returnfalse('fb')} className='social-share' aria-label="Facebook" href={`${'https://facebook.com/sharer/sharer.php?display=page&picture=' + encodeURIComponent(media) + '&quote=' + encodeURIComponent(title + urlshare) + '&u=' + encodeURIComponent(urlshare)}`} target="_blank" rel="noreferrer">
                <i className="fab fa-facebook"></i>
            </a>
            <a id="dropdown" onClickCapture={returnStop2} onClick={() => returnfalse('tw')} className='social-share' aria-label="Twitter" href={`${'https://twitter.com/share?text=' + encodeURIComponent(title) + '&url=' + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`} target="_blank" rel="noreferrer">
                <i className="fab fa-twitter"></i>
            </a>
            <a id="dropdown" onClickCapture={returnStop3} onClick={() => returnfalse('wa')} className='social-share' aria-label="Whatsapp" href={`${'https://api.whatsapp.com/send?text=' + encodeURIComponent(title) + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`} target="_blank" rel="noreferrer">
                <i className="fab fa-whatsapp"></i>
            </a>
            {/* <a id="dropdown" onClickCapture={copy} className="social-share cursor-pointer" onClick={() => copy()} ><i className="fas fa-copy"></i></a> */}
            <a id="dropdown" target="_blank" onClickCapture={returnStop4} href={`mailto:?subject=${title}&body=${title}%0D%0A%0D%0A${urlshare}%0D%0A%0D%0A${ShareValue}`} className="social-share cursor-pointer"  ><i class="fa fa-envelope"></i></a>
        </>
    )
}

export default Share