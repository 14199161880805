/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sharecount } from '../../redux/actions/commonAction';
import ReactGA from "react-ga4";

const ShareWithGallery = (props) => {
    const urlshare = props.url + '\r\n \r\n';

    const { 21: assetbaseUrl, 26: sharemsg } = useSelector((state) => state?.SettingReducer?.data);
    const media = assetbaseUrl?.value + props.media + '?resize=1200,630';
    const title = props.title + '\r\n';
    const ShareValue = sharemsg?.value + '\r\n \r\n';
    // const copytitle = props.title + '\r\n' + urlshare + '\r\n \r\n' + sharemsg?.value;

    useEffect(() => {
        if (props?.url) {
            const localCount = (localStorage.getItem('SandeshLike') ? JSON.parse(localStorage.getItem('SandeshLike')) : {});
            const myCategory = props?.url.split('/')[0];
            if (localCount[myCategory]) {
                localCount[myCategory] = parseInt(localCount[myCategory]) + 1;
                if (parseInt(localCount[myCategory]) > 9999) {
                    localCount[myCategory] = 1000;
                }
            } else {
                localCount[myCategory] = 1;
            }
            localStorage.setItem('SandeshLike', JSON.stringify(localCount));
        }
    }, [props?.url]);

    const dispatch = useDispatch();
    const returnfalse = async (name) => {
        await dispatch(sharecount(name, props?.dataid));
    }

    const copy = (e) => {
        returnfalse('copy');
        var textArea = document.createElement("textarea");
        document.body.className += ' copied';

        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';

        textArea.value = window.location.href;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');

            const node = document.createElement("span");
            const textnode = document.createTextNode("Link copied.");
            node.className = "copyMsg";
            node.setAttribute("id", "copyMsg");
            node.appendChild(textnode);
            document.getElementById('root').appendChild(node);
            setTimeout(() => {
                document.getElementById('copyMsg').outerHTML = '';
            }, 1000);
        } catch (err) {
        }

        document.body.removeChild(textArea);
        document.querySelector('body').classList.remove('copied');
        e.stopPropagation();
        return false;
    }

    function returnStop4(e) {
        document.getElementById(props.id).classList.add('d-none');
        // dispatch(sharecount('wa', props?.dataid));
        ReactGA.event({ category: 'Share', action: 'Email', label: urlshare });
        // e.preventDefault();
        e.stopPropagation();
        return false;
    }

    return (
        <>
            <a className='left-icon-border mb-3' onClickCapture={() => ReactGA.event({ category: 'Share', action: 'Facebook', label: urlshare })} onClick={() => returnfalse('fb')} aria-label="Facebook" href={`${'https://facebook.com/sharer/sharer.php?display=page&picture=' + encodeURIComponent(media) + '&quote=' + encodeURIComponent(title) + '&u=' + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`} target="_blank" rel="noreferrer">
                {/* <a className='left-icon-border mb-3' onClickCapture={() => ReactGA.event({ category: 'Share', action: 'Facebook', label: urlshare })} onClick={() => returnfalse('fb')} aria-label="Facebook" href={`${'https://facebook.com/sharer/sharer.php?display=page&picture=' + encodeURIComponent(media) + '&quote=' + encodeURIComponent(title + urlshare) + '&u=' + encodeURIComponent(urlshare)}`} target="_blank" rel="noreferrer"> */}
                <i className="fab left-fixed-icon-border fa-facebook-f"></i>
            </a>
            <a className='left-icon-border mb-3' onClickCapture={() => ReactGA.event({ category: 'Share', action: 'Twitter', label: urlshare })} onClick={() => returnfalse('tw')} aria-label="Twitter" href={`${'https://twitter.com/share?text=' + encodeURIComponent(title) + '&url=' + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`} target="_blank" rel="noreferrer">
                <i className="fab left-fixed-icon-border fa-twitter"></i>
            </a>
            <a className='left-icon-border mb-3' onClickCapture={() => ReactGA.event({ category: 'Share', action: 'Whatsapp', label: urlshare })} onClick={() => returnfalse('wa')} aria-label="Whatsapp" href={`${'https://api.whatsapp.com/send?text=' + encodeURIComponent(title) + encodeURIComponent(urlshare) + encodeURIComponent(ShareValue)}`} target="_blank" rel="noreferrer">
                <i className="fab left-fixed-icon-border fa-whatsapp"></i>
            </a>
            {/* <a className='left-icon-border' onClickCapture={() => ReactGA.event({ category: 'Share', action: 'Copy', label: urlshare })} onClick={() => copy()} ><i className="fas fa-copy"></i></a> */}
            <a onClick={returnStop4} target="_blank" href={`mailto:?subject=${title}&body=${title}%0D%0A%0D%0A${urlshare}%0D%0A%0D%0A${ShareValue}`} className='left-icon-border' onClickCapture={() => ReactGA.event({ category: 'Share', action: 'Email', label: urlshare })}  ><i class="fa fa-envelope"></i></a>
        </>
    )
}

export default ShareWithGallery


