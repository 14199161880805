import { GALLARY_INNER_FETCH_DATA_SUCCESS, GALLARY_INNER_HAVE_ERROR, GALLARY_INNER_ARE_LOADING } from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
  };
  
  export function GallaryInnerReducer (state = initialState, action) {
    switch (action.type) {
        case GALLARY_INNER_HAVE_ERROR:
            return {
                ...state,
                error:action.hasError,
                loading:false,
            }
    
        case GALLARY_INNER_ARE_LOADING:
           return {
            ...state,
            loading:action.isLoading
        }
    
        case GALLARY_INNER_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data:action.GallaryInner,
                loading:false,
            }

        default:
            return state;
    }
}
