import { CHILDMENU_ARE_LOADING, CHILDMENU_FETCH_DATA_SUCCESS, CHILDMENU_HAVE_ERROR } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function ChildmenuAction(action, payload) {
    switch (action) {
        case CHILDMENU_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case CHILDMENU_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case CHILDMENU_FETCH_DATA_SUCCESS:
            return {
                type: action,
                childmenu: payload,
            };

        default:
            return;
    }
}

export function childmenuFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(ChildmenuAction(CHILDMENU_ARE_LOADING, true));
                if (localStorage.getItem('SandeshTags')) {
                    dispatch(ChildmenuAction(CHILDMENU_FETCH_DATA_SUCCESS, JSON.parse(localStorage.getItem('SandeshTags'))));
                }
                Get(HttpService.childMenu).then((result) => {
                    localStorage.setItem('SandeshTags', JSON.stringify(result.data));
                    dispatch(ChildmenuAction(CHILDMENU_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(ChildmenuAction(CHILDMENU_HAVE_ERROR, true));
        }
    }
}


