import { WIDGET_FETCH_DATA_SUCCESS, WIDGET_HAVE_ERROR, WIDGET_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function widgetAction(action, payload) {
    switch (action) {
        case WIDGET_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };
    
        case WIDGET_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };
    
        case WIDGET_FETCH_DATA_SUCCESS:
            return {
                type: action,
                widget: payload,
            };

        default:
            return;
    }
}

export function widgetFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(widgetAction(WIDGET_ARE_LOADING, true));
                Get(HttpService.widget).then((result) => {
                    dispatch(widgetAction(WIDGET_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(widgetAction(WIDGET_HAVE_ERROR, true));
        }
    }
}
