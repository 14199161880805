import { GALLARY_FETCH_DATA_SUCCESS, GALLARY_HAVE_ERROR, GALLARY_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function GallaryAction(action, payload) {
    switch (action) {
        case GALLARY_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };
    
        case GALLARY_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };
    
        case GALLARY_FETCH_DATA_SUCCESS:
            return {
                type: action,
                Gallary: payload,
            };

        default:
            return;
    }
}

export function gallaryFetchData(limit) {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(GallaryAction(GALLARY_ARE_LOADING, true));
                Get(HttpService.gallary).then((result) => {
                    dispatch(GallaryAction(GALLARY_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(GallaryAction(GALLARY_HAVE_ERROR, true));
        }
    }
}
