import { HOMESECONDARY_FETCH_DATA_SUCCESS, HOMESECONDARY_HAVE_ERROR, HOMESECONDARY_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function homeSecondaryAction(action, payload) {
    switch (action) {
        case HOMESECONDARY_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case HOMESECONDARY_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case HOMESECONDARY_FETCH_DATA_SUCCESS:
            return {
                type: action,
                homeSecondary: payload,
            };

        default:
            return;
    }
}

export function homeSecondaryFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(homeSecondaryAction(HOMESECONDARY_ARE_LOADING, true));
                if (localStorage.getItem('SandeshHomeSub')) {
                    dispatch(homeSecondaryAction(HOMESECONDARY_FETCH_DATA_SUCCESS, JSON.parse(localStorage.getItem('SandeshHomeSub'))));
                }
                Get(HttpService.homeSecondary).then((result) => {
                    localStorage.setItem('SandeshHomeSub', JSON.stringify(result.data));
                    dispatch(homeSecondaryAction(HOMESECONDARY_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(homeSecondaryAction(HOMESECONDARY_HAVE_ERROR, true));
        }
    }
}
