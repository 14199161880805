import { COMMENT_ARE_LOADING, COMMENT_HAVE_ERROR, COMMENT_FETCH_DATA_SUCCESS } from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
};

export function CommentReducer(state = initialState, action) {
    switch (action.type) {
        case COMMENT_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case COMMENT_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }

        case COMMENT_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
            }

        default:
            return state;
    }
}
