import { POST_FETCH_DATA_SUCCESS, POST_HAVE_ERROR, POST_ARE_LOADING } from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
};

export function PostReducer(state = initialState, action) {
    switch (action.type) {
        case POST_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case POST_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading,
                data: action.Post
            }

        case POST_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.Post,
                loading: false,
            }

        default:
            return state;
    }
}
