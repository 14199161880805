/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { pageFetchData } from '../../redux/actions/pageAction';


function Pages() {
    const { id } = useParams();
    const PageList = useSelector((state) => state?.PageReducer?.data);
    const loading = useSelector((state) => state?.PageReducer?.loading);
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(pageFetchData(id));
    }, [id]);

    return (
        <>
            {PageList?.title &&
                <Helmet>
                    <title>{PageList?.title} | Sandesh</title>
                </Helmet>
            }
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {
                            <div className="page-content" style={{ opacity: (loading) ? 0.5 : 1 }}>
                                <h1>{PageList?.title}</h1>
                                <div className="content-area" dangerouslySetInnerHTML={{ __html: PageList?.content }} />
                            </div>
                        }
                    </div>

                </div>

            </div>
        </>
    )
}

export default Pages
