import { combineReducers } from 'redux';
import { MenuReducer } from './menu';
import { FooterReducer } from './footer';
import { PageReducer } from './page';
import { InvRelReducer } from './inv-rel';
import { GallaryReducer } from './gallary'
import { SettingReducer } from './setting'
import { GallaryInnerReducer } from './gallaryInner';
import { HomeSecondaryReducer } from './homeSecondary';
import { HomePrimaryReducer } from './homePrimary';
import { PostReducer } from './post';
import { VideoInnerReducer } from './videoInner';
import { CategoryReducer, EntertainmentReducer } from './category';
import { CategoryAstroReducer } from './categoryAstro';
import { GujaratSamagraCityReducer } from './gujaratSamagraCity';
import { VideoReducer } from './video';
import { LogingReducer } from './login';
import { CityReducer } from './city';
import { CityResultReducer } from './cityResult';
import { TithiReducer } from './tithi';
import { ChildmenuReducer } from './childmenu';
import { HoroscopeReducer } from './horoscope';
import { widgetReducer } from './widgetReducer';
import { EPaperCategory } from './EPaperCategory';
import { ePaperMenuReducer } from './ePaperMenu';
import { searchResult } from './searchResultAction';
import { inputSearchReducer } from './searchValue';
import { ePaperImageReducer } from './EPaperImage';
import { CommentReducer } from './comment';
import { VideoCategoryReducer } from './videoCategory';
import { SportScoreReducer } from './sportScore';
import { MarqueeReducer } from './marquee';


export default combineReducers({
    SettingReducer,
    MenuReducer,
    FooterReducer,
    PageReducer,
    InvRelReducer,
    GallaryReducer,
    GallaryInnerReducer,
    HomeSecondaryReducer,
    HomePrimaryReducer,
    PostReducer,
    VideoInnerReducer,
    CategoryReducer,
    EntertainmentReducer,
    CategoryAstroReducer,
    ePaperImageReducer,
    GujaratSamagraCityReducer,
    VideoReducer,
    LogingReducer,
    CityReducer,
    CityResultReducer,
    TithiReducer,
    ChildmenuReducer,
    HoroscopeReducer,
    widgetReducer,
    EPaperCategory,
    ePaperMenuReducer,
    searchResult,
    searchValue: inputSearchReducer,
    CommentReducer,
    VideoCategoryReducer,
    SportScoreReducer,
    MarqueeReducer,
});
