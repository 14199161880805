/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service';
import { Helmet } from 'react-helmet';


const Rss = () => {
    const [data, setapiData] = useState({});

    useEffect(() => {
        async function fetchData() {
            const myMetroData = await Get(HttpService.rssURL);
            setapiData(myMetroData?.data);
        };
        fetchData();
    }, []);

    return (
        <>
            <section className='rss'>
                <div className='container'>
                    <Helmet>
                        <title>RSS Feeds | Sandesh</title>
                    </Helmet>

                    {/* <div className='rss-title mt-3'>
                        <p>RSS Feeds</p>
                    </div> */}
                    {/* <hr /> */}
                    <div className='row my-3'>
                        <div className='col-md-2'>

                        </div>
                        <div className='col-md-8'>
                            <h1>RSS Feeds</h1>
                            <div className='rss-top-box'>
                                <span>
                                    There are billions of pages on the Internet and trying to find what you want can often prove to be really tedious. But you can now have customised content delivered to your desktop through RSS (Really Simple Syndication) feeds.
                                    <br />
                                    <br />
                                    sandesh.com offers free headlines for personal, non-commercial use via Really Simple Syndication (RSS 2.0). This is an easy way to get the latest news from sandesh.com even if you are not logged on to the site.
                                </span>
                            </div>
                            <div className='rss-feed-sec-title'>
                                <span>
                                    The feeds are available for the following categories:
                                </span>
                                <div className='row'>
                                    {(data?.rss_category?.length > 0) ? data?.rss_category?.map((tl) => {
                                        return (
                                            <div className='col-md-3 rss-feed my-1 col-6'>
                                                <a target="_blank" href={`/rss/${tl?.url}.xml`}><i className="fa fa-rss mr-2"></i>{tl?.name}</a>
                                            </div>
                                        );
                                    }) : <div className='col-md-12 text-center'>Please wait...</div>}
                                </div>

                                <span>
                                    The feeds are available for the following cities:
                                </span>
                                <div className='row'>
                                    {(data?.city?.length > 0) ? data?.city?.map((tl) => {
                                        return (
                                            <div className='col-md-3 rss-feed my-1 col-6'>
                                                <a target="_blank" href={`/rss/${tl?.url}-city.xml`}><i className="fa fa-rss mr-2"></i>{tl?.name}</a>
                                            </div>
                                        );
                                    }) : <div className='col-md-12 text-center'>Please wait...</div>}
                                </div>

                                <div className='rss-use-box'>
                                    <span>How can I use RSS?</span>
                                    <p>Click on the section you are interested in and install a news reader that displays RSS feeds. A number of free and commercial news readers are available for download. Once the RSS reader is set to sandesh.com, it will poll the website for the latest headlines.</p>
                                    <hr className='mb-1' />
                                    <span>Terms of Use</span>
                                    <ul>
                                        <li>sandesh.com RSS feeds are provided free of charge for use by individuals for personal, non-commercial use.</li>
                                        <li>Attribution to sandesh.com must be provided in connection with the use of these feeds.</li>
                                        <li>If you provide this attribution in text, please use: "sandesh.com".</li>
                                        <li>If you provide this attribution with a graphic, please use the sandesh.com logo that has been included in the feed.</li>
                                    </ul>
                                </div>
                                {/* <div className='rss-disclaimer'>
                                    <span className='disclaimer-title'>
                                        Disclaimer
                                    </span>
                                    <p>
                                        All feeds ("RSS Feed") and/or their contents that sandesh.com ("Site") is displaying contains material which is derived in whole or in part from material supplied by the New Delhi Television Limited ("sandesh"), various new agencies and other sources, and is protected by international copyright and trademark laws. The Site processes all information automatically using automated software without any human intervention or screening. Therefore the Site is not responsible for any (part) of this content. The copyright of the feeds' content belongs to it's author or publisher. The Site may modify, suspend or discontinue any aspect of the RSS Feed at any time, including, without limitation, the availability of any Site content. The Site may also impose limits on certain features and services or restrict your access to parts or all of the RSS Feed without notice or liability of any kind. The User agrees that the RSS Feed is personal to the User and that the User may not resell, lease, license, assign, redistribute or otherwise transfer any portion of the RSS Feed without attribution to the Site. The User agrees that any User providing any attribution in text shall use the Site name and any User providing attribution with graphic shall use the Site logo that has been included in the Feed. The Site does not represent or warrant that every action you take with regard to your account and related activities in connection with the RSS Feed, including, without limitation, the Site Content, will be lawful in any particular jurisdiction. It is incumbent upon you to know the laws that pertain to you in your jurisdiction and act lawfully at all times when using the RSS Feed, including, without limitation, the Site Content.
                                    </p>
                                </div> */}
                            </div>
                        </div>
                        <div className='col-md-2'>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Rss;