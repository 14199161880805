import { EPAPERIMAGE_ARE_LOADING, EPAPERIMAGE_HAVE_ERROR, EPAPERIMAGE_FETCH_DATA_SUCCESS } from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
};

export function ePaperImageReducer(state = initialState, action) {
    switch (action.type) {
        case EPAPERIMAGE_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case EPAPERIMAGE_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }

        case EPAPERIMAGE_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.ePaperImage,
                loading: false,
            }

        default:
            return state;
    }
}