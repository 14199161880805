import {FOOTER_ARE_LOADING, FOOTER_FETCH_DATA_SUCCESS, FOOTER_HAVE_ERROR} from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
  };
  
  export function FooterReducer (state = initialState, action) {
    switch (action.type) {
        case FOOTER_HAVE_ERROR:
            return {
                ...state,
                error:action.hasError,
                loading:false,
            }
    
        case FOOTER_ARE_LOADING:
           return {
            ...state,
            loading:action.isLoading
        }
    
        case FOOTER_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data:action.Footer,
                loading:false,
            }

        default:
            return state;
    }
}
