import { ITEMS_ARE_LOADING, ITEMS_FETCH_DATA_SUCCESS, ITEMS_HAVE_ERROR } from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
};

export function MenuReducer(state = initialState, action) {
    switch (action.type) {
        case ITEMS_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case ITEMS_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }

        case ITEMS_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.Menu,
                loading: false,
            }

        default:
            return state;
    }
}

