import {GALLARY_ARE_LOADING, GALLARY_HAVE_ERROR, GALLARY_FETCH_DATA_SUCCESS} from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
  };
  
  export function GallaryReducer (state = initialState, action) {
    switch (action.type) {
        case GALLARY_HAVE_ERROR:
            return {
                ...state,
                error:action.hasError,
                loading:false,
            }
    
        case GALLARY_ARE_LOADING:
           return {
            ...state,
            loading:action.isLoading
        }
    
        case GALLARY_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data:action.Gallary,
                loading:false,
            }

        default:
            return state;
    }
}
