// import { CONTACTUS_ARE_LOADING, CONTACTUS_FETCH_DATA_SUCCESS, CONTACTUS_HAVE_ERROR } from '../actionType';
import { PAGE_ARE_LOADING, PAGE_FETCH_DATA_SUCCESS, PAGE_HAVE_ERROR } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service';

export function PageAction(action, payload) {
    switch (action) {
        case PAGE_HAVE_ERROR:
            return {
                type: action,
                hasError: payload,
                isLoading: false
            };

        case PAGE_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case PAGE_FETCH_DATA_SUCCESS:
            return {
                type: action,
                Page: payload,
                isLoading: false
            };

        default:
            return;
    }
}

export function pageFetchData(id) {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(PageAction(PAGE_ARE_LOADING, true));
                Get(HttpService?.baseurl + id).then((result) => {
                    dispatch(PageAction(PAGE_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(PageAction(PAGE_HAVE_ERROR, true));
        }
    }
}



