/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import { useEffect, useState } from 'react';
import '../../styles/comment.css';
import { useDispatch, useSelector } from 'react-redux';
import { postComment, reportComment } from '../../redux/actions/commonAction';
import { PostFetchData } from '../../redux/actions/postAction';
import { CommentFetchData } from '../../redux/actions/commentAction';
import { MentionsInput, Mention } from "react-mentions";

function Comment(props) {
  const dispatch = useDispatch();
  // const innerDataComments = useSelector((state) => state?.PostReducer?.data?.comments);
  const innerDataComments = useSelector((state) => state?.CommentReducer?.data);
  const innerData = useSelector((state) => state?.PostReducer?.data);

  // console.log('commentdatacommentdata', commentdata);
  // console.log('commentdatacommentdata old', innerDataComments);

  const [activeCls, setactiveCls] = useState([]);
  const [comData, setcomData] = useState([]);
  const [, setMessage] = useState('');
  const [checkbox, setcheckbox] = useState(0);
  const [commentData, setCommentData] = useState({ notify: 0, comment: '', id: localStorage.getItem('userID'), postid: props?.postId, parent_id: '' });


  useEffect(() => {
    document.querySelector('.comment-box')?.classList.add('active');
    var model1 = document.querySelector('.comment-box');
    window?.addEventListener('keydown', function (event) {
      if (event.key === "Escape") {
        model1.style.display = 'none';
      }
    });
  }, []);

  const closemodal = () => {
    document.querySelector('.comment-box')?.classList.remove('active');
    props?.postCommentModalchk();
  }

  useEffect(() => {
    dispatch(CommentFetchData(props?.postId));
  }, []);

  const PostComment = () => {
    // alert("clicked");
    const dataObj = {
      ...commentData, notify: checkbox
    }
    dispatch(postComment(dataObj)).then((res) => {
      if (res?.status) {
        const count = res?.count;
        // dispatch(PostFetchData(window.location.pathname));
        setMessage(res?.message);
        dispatch(CommentFetchData(props?.postId));
        setCommentData({ notify: 0, comment: '', id: localStorage.getItem('userID'), postid: props?.postId, parent_id: '' })
        setcheckbox(0);
        props?.postCommentcount(count);
      }
      setTimeout(() => {
        setMessage(undefined)
      }, 5000);
    })
  }

  const reportCommentHandler = (commentId) => {
    const dataObj = {
      comment_id: commentId,
      id: JSON.parse(localStorage.getItem('userID')),
    }
    dispatch(reportComment(dataObj)).then((res) => {
      if (res) {
        dispatch(PostFetchData(window.location.pathname));
      }
    })
  }

  const username = [];

  if (innerDataComments?.length) {
    let userNames = [];
    innerDataComments?.map((el) => {
      if (el?.child.length) {
        el?.child?.map((elx) => {
          if (!userNames?.includes(elx?.name)) {
            userNames?.push(elx?.name)
            username.push({ id: elx?.id, display: `@${elx?.name}` })
          }
        });
      }
      if (!userNames?.includes(el?.name)) {
        userNames?.push(el?.name)
        username.push({ id: el?.id, display: `@${el?.name}` })
      }
    });
  }

  const showResponses = (idx) => {
    let tmpArray = activeCls;
    if (tmpArray.includes(idx)) {
      tmpArray = tmpArray.filter((el) => el !== idx);
    } else {
      tmpArray.push(idx);
    }
    setactiveCls([...tmpArray]);
    return false;
  }

  const replayPostComment = (id) => {
    if (id) {
      setCommentData({ ...commentData, parent_id: id });
    }
    document.getElementById('commenttextarea').focus();
  }

  const NotifyMe = () => {
    const notify = checkbox === 0 ? 1 : 0;
    setcheckbox(notify);
  }

  const dataOrder = (val) => {
    if (val === 'oldest') {
      comData.sort((a, b) => a?.id - b?.id);
      setcomData((arr) => [...arr]);
    }
    if (val === 'newest') {
      comData.sort((a, b) => b?.id - a?.id)
      setcomData((arr) => [...arr]);
    }
  }

  // useEffect(() => {
  //   setcomData(props?.innerDataProps?.comments);
  // }, [props?.innerDataProps])

  useEffect(() => {
    setcomData(innerDataComments);
  }, [innerDataComments])

  if ('/' + innerData?.url !== window.location.pathname) closemodal();

  return (
    <section className="comment_section">
      <div className="container">
        <div className="comment-box">
          <div className='closebtn'>
            <i onClickCapture={() => { closemodal() }} className="fa fa-times icon-color-times"></i>
          </div>
          <div className="inn-block">
            <div className="signin-section">
              <div className="comment_widget1" id="comment_widget1">
                <div className="comment_head">
                  <i className="fas fa-comment-alt"></i>
                  <span>{innerDataComments?.length} Comments and {Array.prototype.concat.apply([], innerDataComments?.map((el) => el?.child)?.filter((el) => el?.length > 0))?.length} replies</span>
                </div>
                <div className="write_box_widget" id="write_box_widget">
                  <div id="comment-reply"></div>
                  <form action="" id="comment-form" method="post">
                    <div className="new_comment_box">
                      {/* <textarea
                        value={commentData?.comment}
                        onChange={(e) => setCommentData({ ...commentData, comment: e.target.value })}
                        name="comment"
                        id="commenttextarea"
                        rows="1"
                        cols="10"
                        placeholder="Share your thoughts."
                        style={{ height: "10em" }}
                      >
                      </textarea> */}
                      <MentionsInput
                        value={commentData?.comment}
                        onChange={(e) => setCommentData({ ...commentData, comment: e.target.value })}
                        className="mentionWrapper"
                        name="comment"
                        id="commenttextarea"
                        placeholder="Share your thoughts."
                      >
                        <Mention
                          trigger="@"
                          markup="(__display__)"
                          data={username}
                          className="mentionedFriend"
                        />
                      </MentionsInput>
                      <div className="form-check box-setin-textarea">
                        {checkbox === 0 ? (
                          <input className="form-check-input" onClickCapture={() => { NotifyMe() }} type="checkbox" value="" id="flexCheckDefault" />
                        ) : (
                          <input className="form-check-input" checked onClickCapture={() => { NotifyMe() }} type="checkbox" value="" id="flexCheckDefault" />
                        )}
                        <label className="form-check-label" for="flexCheckDefault">
                          Notify Me
                        </label>
                      </div>
                      <button onClick={() => { PostComment() }} type="button" id="replayBtn"><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                      <input type="hidden" id="hdn_tag" value="" />
                    </div>
                  </form>
                </div>
                <div className="sortby d-none">
                  <div className="select-style">
                    <select
                      name="comment_sort"
                      placeholder="Sort By"
                      onChange={(e) => { dataOrder(e.target.value) }}
                    // onchange="javascript:getSortUrl( self.location, this.value)"
                    >
                      <option value="newest" selected="selected">Latest</option>
                      <option value="oldest">Oldest</option>
                      {/* <option value="likes">Most Liked</option> */}
                    </select>
                  </div>
                </div>
                {
                  comData && comData?.map((el, idx) => (
                    <>
                      <div className="user_comment" key={idx} data-reply={(el?.id === commentData.parent_id) ? 'replied' : ''}>
                        <div className="user_head">
                          <a className="user_name pr-1">
                            {el?.name}
                          </a>
                          <input
                            type="hidden"
                            name="hdntag[]"
                            className="hdntag"
                            value="@ONLY TIMEPASSS TP1"
                          />
                          <span className="user_time">{el?.l_date}</span>
                        </div>
                        <div>
                          <p className="user_content">
                            {el?.content}{" "}
                          </p>
                        </div>
                        <div className="com_interactives">
                          <span className=''>
                            <a className="user_reply" href="javascript:void(0)" onClickCapture={() => { replayPostComment(el?.id) }}>
                              Reply
                            </a>
                          </span>
                          {!el?.child?.length ? '' :
                            <span className='' onClickCapture={() => { showResponses(idx) }}>
                              <a className="user_reply" href="javascript:void(0)" style={{ color: activeCls?.includes(idx) ? '#e61919' : '#21ce21' }}>
                                {activeCls?.includes(idx) ? 'Hide Responses' : 'Show Responses'}
                              </a>
                            </span>
                          }
                          <span className="borderRt " id="report-abuse-link-9996925">
                            <a href="javascript:void(0)" onClickCapture={() => { reportCommentHandler(el?.id) }}>
                              Report Abuse
                            </a>
                          </span>
                        </div>
                      </div>
                      {el?.child?.map((els) => (
                        <>
                          {activeCls?.includes(idx) ? (
                            <div className="user_comment_inner" data-reply={(el?.id === commentData.parent_id) ? 'replied' : ''}>
                              <div className="user_head">
                                <a className="user_name pr-1">
                                  {els?.name}
                                </a>
                                <input
                                  type="hidden"
                                  name="hdntag[]"
                                  className="hdntag"
                                  value="@ONLY TIMEPASSS TP1"
                                />
                                <span className="user_time">{els?.l_date}</span>
                              </div>
                              <div>
                                <p className="user_content">
                                  {els?.content}{" "}
                                </p>
                              </div>
                              {/* <div className="com_interactives_inner">
                                <span>
                                  <a className="user_reply" href="javascript:void(0)" onClickCapture={() => { replayPostComment(el?.id) }}>
                                    Reply
                                  </a>
                                </span>
                              </div> */}
                            </div>
                          ) : ''}
                        </>
                      ))}
                    </>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comment;