import { CATEGORY_FETCH_DATA_SUCCESS, CATEGORY_HAVE_ERROR, CATEGORY_ARE_LOADING, ENTERTAINMENT_FETCH_DATA_SUCCESS, ENTERTAINMENT_HAVE_ERROR, ENTERTAINMENT_ARE_LOADING } from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
};

export function CategoryReducer(state = initialState, action) {
    switch (action.type) {
        case CATEGORY_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case CATEGORY_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }

        case CATEGORY_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.Category,
                loading: false,
            }

        default:
            return state;
    }
}


export function EntertainmentReducer(state = initialState, action) {
    switch (action.type) {
        case ENTERTAINMENT_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case ENTERTAINMENT_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }

        case ENTERTAINMENT_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action?.Category?.data?.posts || [],
                loading: false,
            }

        default:
            return state;
    }
}
