import { HOMESECONDARY_FETCH_DATA_SUCCESS, HOMESECONDARY_HAVE_ERROR, HOMESECONDARY_ARE_LOADING } from '../actionType';

const defaultData = localStorage.getItem('SandeshHomeSub') || [];
const initialState = {
    data: defaultData,
    loading: true,
    error: []
};

export function HomeSecondaryReducer(state = initialState, action) {
    switch (action.type) {
        case HOMESECONDARY_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case HOMESECONDARY_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }

        case HOMESECONDARY_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.homeSecondary,
                loading: false,
            }

        default:
            return state;
    }
}
