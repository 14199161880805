import React from 'react'
import Header from '../subcomponents/astrologycomponent/Header';
// import Header from '../subcomponents/';

const AstrologyComponent = () => {
    return (
        <>
            <section className='astrology-component'>
                <div className='container'>
                    <Header />
                </div>
            </section>
        </>
    )
}

export default AstrologyComponent
