import { ITEMS_ARE_LOADING, ITEMS_FETCH_DATA_SUCCESS, ITEMS_HAVE_ERROR } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function MenuAction(action, payload) {
    switch (action) {
        case ITEMS_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case ITEMS_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case ITEMS_FETCH_DATA_SUCCESS:
            return {
                type: action,
                Menu: payload,
            };

        default:
            return;
    }
}

export function menuFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(MenuAction(ITEMS_ARE_LOADING, true));
                if (localStorage.getItem('SandeshMenu')) {
                    dispatch(MenuAction(ITEMS_FETCH_DATA_SUCCESS, JSON.parse(localStorage.getItem('SandeshMenu'))));
                }
                Get(HttpService.menu).then((result) => {
                    localStorage.setItem('SandeshMenu', JSON.stringify(result.data));
                    dispatch(MenuAction(ITEMS_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(MenuAction(ITEMS_HAVE_ERROR, true));
        }
    }
}


