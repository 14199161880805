import { SPORTSCORE_FETCH_DATA_SUCCESS, SPORTSCORE_HAVE_ERROR, SPORTSCORE_ARE_LOADING } from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
};

export function SportScoreReducer(state = initialState, action) {
    switch (action.type) {
        case SPORTSCORE_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case SPORTSCORE_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }

        case SPORTSCORE_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.sportScore,
                loading: false,
            }

        default:
            return state;
    }
}
