import { SPORTSCORE_FETCH_DATA_SUCCESS, SPORTSCORE_HAVE_ERROR, SPORTSCORE_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function sportScoreAction(action, payload) {
    switch (action) {
        case SPORTSCORE_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case SPORTSCORE_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case SPORTSCORE_FETCH_DATA_SUCCESS:
            return {
                type: action,
                sportScore: payload,
            };

        default:
            return;
    }
}

export function sportScoreFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(sportScoreAction(SPORTSCORE_ARE_LOADING, true));
                Get(HttpService.sportScore).then((result) => {
                    dispatch(sportScoreAction(SPORTSCORE_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(sportScoreAction(SPORTSCORE_HAVE_ERROR, true));
        }
    }
}
