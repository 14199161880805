
import { CITY_FETCH_DATA_SUCCESS, CITY_HAVE_ERROR, CITY_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function CityAction(action, payload) {
    switch (action) {
        case CITY_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };
    
        case CITY_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };
    
        case CITY_FETCH_DATA_SUCCESS:
            return {
                type: action,
                city: payload,
            };

        default:
            return;
    }
}

export function CityFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(CityAction(CITY_ARE_LOADING, true));
                Get(HttpService.city).then((result) => {
                    dispatch(CityAction(CITY_FETCH_DATA_SUCCESS, result));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(CityAction(CITY_HAVE_ERROR, true));
        }
    }
}
