import { HOMEPRIMARY_FETCH_DATA_SUCCESS, HOMEPRIMARY_HAVE_ERROR, HOMEPRIMARY_ARE_LOADING } from '../actionType';

const defaultData = localStorage.getItem('SandeshHome') || [];
const initialState = {
    data: defaultData,
    loading: true,
    error: []
};

export function HomePrimaryReducer(state = initialState, action) {
    switch (action.type) {
        case HOMEPRIMARY_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case HOMEPRIMARY_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }

        case HOMEPRIMARY_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.homePrimary,
                loading: false,
            }

        default:
            return state;
    }
}
