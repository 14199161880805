import {HOROSCOPE_ARE_LOADING, HOROSCOPE_FETCH_DATA_SUCCESS, HOROSCOPE_HAVE_ERROR} from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function HoroscopeAction(action, payload) {
    switch (action) {
        case HOROSCOPE_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };
    
        case HOROSCOPE_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };
    
        case HOROSCOPE_FETCH_DATA_SUCCESS:
            return {
                type: action,
                horoscope: payload,
            };

        default:
            return;
    }
}

export function horoscopeFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(HoroscopeAction(HOROSCOPE_ARE_LOADING, true));
                Get(HttpService.horoscope).then((result) => {
                    dispatch(HoroscopeAction(HOROSCOPE_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(HoroscopeAction(HOROSCOPE_HAVE_ERROR, true));
        }
    }
}


