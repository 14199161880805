import {HOROSCOPE_ARE_LOADING, HOROSCOPE_FETCH_DATA_SUCCESS, HOROSCOPE_HAVE_ERROR} from '../actionType';

const initialState = {
    data: [],
    loading: true,
    error: []
  };
  
  export function HoroscopeReducer (state = initialState, action) {
    switch (action.type) {
        case HOROSCOPE_HAVE_ERROR:
            return {
                ...state,
                error:action.hasError,
                loading:false,
            }
    
        case HOROSCOPE_ARE_LOADING:
           return {
            ...state,
            loading:action.isLoading
        }
    
        case HOROSCOPE_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data:action.horoscope,
                loading:false,
            }

        default:
            return state;
    }
}

