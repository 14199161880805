/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import InjectScript from '../components/CustomScript';
import { useSelector } from 'react-redux';
const MyViewScript = (props) => {
    const { [props.argSmall]: scriptMobile } = useSelector((state) => state?.widgetReducer?.data);
    const { [props.argLarge]: scriptWeb } = useSelector((state) => state?.widgetReducer?.data);
    const clientWidth = document.body.clientWidth;

    if (clientWidth > 991) {
        if ((clientWidth > 991) && (scriptWeb?.[0]?.script) && (scriptWeb?.[0]?.script !== '')) {
            return (<div key={window.location.href} style={{ padding: (props?.addstyle) ? '5px 0' : 0 }}><InjectScript script={`${scriptWeb?.[0]?.script || ''}`} /></div>)
        } else {
            return ''
        }
    } else {
        if ((scriptMobile?.[0]?.script) && (scriptMobile?.[0]?.script !== '')) {
            return (<div key={window.location.href} style={{ padding: (props?.addstyle) ? '5px 0' : 0 }}><InjectScript script={`${scriptMobile?.[0]?.script || ''}`} /></div>)
        } else {
            return ''
        }
    }
}
export default MyViewScript;
