/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CityFetchData } from '../../redux/actions/cityAction';
function LocationSelect(props) {
    const citiesName = useSelector((state) => state?.CityReducer?.data?.data);
    let dispatch = useDispatch();
    const [cityName, setCityName] = useState([])
    useEffect(() => {
        dispatch(CityFetchData())
    }, [])
    useEffect(() => {
        if (localStorage.getItem('activeCity')) {
            const activeCitiesName = JSON.parse(localStorage.getItem('activeCity')) || []
            setActiveCity(activeCitiesName?.map((els) => els?.name));
        }
        citiesName && setCityName(citiesName);
    }, [citiesName])
    const [activeCity, setActiveCity] = useState([]);
    const saveCityHandler = () => {
        document.getElementsByClassName('close')[0]?.click()
        // const citiesURL = cityName?.filter((els) => activeCity?.includes(els?.name));
        const citiesURL = citiesName?.filter((els) => activeCity?.includes(els?.name));
        props?.visibleCity(citiesURL);
        searchHandler('');
        document.getElementsByClassName('searchsamgra')[0].value = '';
    };
    const cityHandler = (cityName) => {
        if (activeCity?.includes(cityName)) {
            const mapedData = activeCity?.filter((els) => els !== cityName);
            setActiveCity(mapedData);
        } else {
            const data = [...activeCity];
            data?.push(cityName);
            setActiveCity(data);
        }
    }
    const searchHandler = (searchValue) => {
        const filteredValues = citiesName?.filter((els) => els?.name?.toLowerCase()?.includes(searchValue?.toLowerCase().trim()))
        setCityName(filteredValues);
    }
    return (
        <section className="popup">
            {/* <!--    <div className="container">--> */}
            <div className="row">

                <div className="col-sm-2">
                    <li className="nav-item">
                        <a data-toggle="modal" data-target="#exampleModal"><i className="fas fa-plus plusIcon"></i></a>
                    </li>


                    {/* <!-- Modal --> */}
                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <form>
                                        <div className="row">
                                            <div className="col-11">
                                                <div className="form-group">
                                                    <input onChange={(e) => searchHandler(e.target.value)} type="text" className="form-control searchsamgra" placeholder="Find City" />
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                <div className="form-group has-search">
                                                    <span className="fa fa-search form-control-feedback"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body scroll-body">
                                    {/* <h4>State</h4>
                                    <nav className="nav nav-pills">
                                        <a className="flex-sm-fill text-sm-center nav-link active" href="#">મારું ગુજરાત <span className="badge badge-pill badge-primary badge-close">&times;</span></a>
                                        <a className="flex-sm-fill text-sm-center nav-link" href="#">NRG</a>
                                        <a className="flex-sm-fill text-sm-center nav-link" href="#">મુંબઇ</a>
                                    </nav>
                                    <h4>City</h4> */}
                                    <nav className="nav nav-pills">
                                        {cityName?.length > 0 && cityName?.map((els) => {
                                            return (<><a onClick={() => cityHandler(els?.name)} class={`text-sm-center nav-link ${activeCity?.includes(els?.name) && `active`}`} href="javascript:void(0)">{els?.name} {activeCity?.includes(els?.name) && <span className="badge badge-pill badge-primary badge-close">&times;</span>}</a></>)
                                        })}
                                        {/* <a className=" text-sm-center nav-link active" href="#">અમદાવાદ<span className="badge badge-pill badge-primary badge-close">&times;</span></a>
                                        <a onClick={() => setCity({ name:'સુરત', select: city.name === 'સુરત' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'સુરત' &&  city.select) && `active`}`} href="#">સુરત { (city.name === 'સુરત' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'વડોદરા', select: city.name === 'વડોદરા' && !city.select})} class={`text-sm-center nav-link ${(city.name === 'વડોદરા' &&  city.select) && `active`}`} href="#">વડોદરા  { (city.name === 'વડોદરા' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'રાજકોટા', select: city.name === 'રાજકોટા' && !city.select})} class={`text-sm-center nav-link ${(city.name === 'રાજકોટા' &&  city.select) && `active`}`} href="#">રાજકોટા  { (city.name === 'રાજકોટા' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'ભાવનગર', select: city.name === 'ભાવનગર' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'ભાવનગર' &&  city.select) && `active`}`} href="#">ભાવનગર  { (city.name === 'ભાવનગર' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'ગાંધીનગર', select: city.name === 'ગાંધીનગર' && !city.select })}class={`text-sm-center nav-link ${(city.name === 'ગાંધીનગર' &&  city.select) && `active`}`} href="#">ગાંધીનગર  { (city.name === 'ગાંધીનગર' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'મહેસાણા', select: city.name === 'મહેસાણા' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'મહેસાણા' &&  city.select) && `active`}`} href="#">મહેસાણા { (city.name === 'મહેસાણા' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'આણંદા', select: city.name === 'આણંદા' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'આણંદા' &&  city.select) && `active`}`} href="#">આણંદા { (city.name === 'આણંદા' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'જામનગર', select: city.name === 'જામનગર' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'જામનગર' &&  city.select) && `active`}`} href="#">જામનગર { (city.name === 'જામનગર' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'જુનાગઢ', select: city.name === 'જુનાગઢ' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'જુનાગઢ' &&  city.select) && `active`}`} href="#">જુનાગઢ { (city.name === 'જુનાગઢ' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'સુરેન્દ્રનગર', select: city.name === 'સુરેન્દ્રનગર' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'સુરેન્દ્રનગર' &&  city.select) && `active`}`} href="#">સુરેન્દ્રનગર { (city.name === 'સુરેન્દ્રનગર' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'નવસારી', select: city.name === 'નવસારી' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'નવસારી' &&  city.select) && `active`}`} href="#">નવસારી { (city.name === 'નવસારી' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'વલસાડા', select: city.name === 'વલસાડા' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'વલસાડા' &&  city.select) && `active`}`} href="#">વલસાડા { (city.name === 'વલસાડા' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'ભરૂચા', select: city.name === 'ભરૂચા' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'ભરૂચા' &&  city.select) && `active`}`} href="#">ભરૂચા { (city.name === 'ભરૂચા' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'અમરેલી', select: city.name === 'અમરેલી' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'અમરેલી' &&  city.select) && `active`}`} href="#">અમરેલી { (city.name === 'અમરેલી' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a>
                                        <a onClick={() => setCity({ name:'પાલનપુર', select: city.name === 'પાલનપુર' && !city.select })} class={`text-sm-center nav-link ${(city.name === 'પાલનપુર' &&  city.select) && `active`}`} href="#">પાલનપુર (બનાસકાંઠા) { (city.name === 'પાલનપુર' &&  city.select) && <span className="badge badge-pill badge-primary badge-close">&times;</span> }</a> */}
                                    </nav>
                                </div>
                                <div className="modal-footer">
                                    {/* <!--                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>--> */}
                                    <button onClick={saveCityHandler} type="button" className="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* <!--    </div>--> */}
        </section>
    )
}

export default LocationSelect;
