import { VIDEOCATEGORY_FETCH_DATA_SUCCESS, VIDEOCATEGORY_HAVE_ERROR, VIDEOCATEGORY_ARE_LOADING } from '../actionType';


const initialState = {
    data: [],
    loading: true,
    error: []
};

export function VideoCategoryReducer(state = initialState, action) {
    switch (action.type) {
        case VIDEOCATEGORY_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case VIDEOCATEGORY_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }

        case VIDEOCATEGORY_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.VideoCategory,
                loading: false,
            }

        default:
            return state;
    }
}
