import {VIDEO_FETCH_DATA_SUCCESS,VIDEO_HAVE_ERROR,VIDEO_ARE_LOADING } from '../actionType';


const initialState = {
    data: [],
    loading: true,
    error: []
  };
  
  export function VideoReducer (state = initialState, action) {
    switch (action.type) {
        case VIDEO_HAVE_ERROR:
            return {
                ...state,
                error:action.hasError,
                loading:false,
            }
    
        case VIDEO_ARE_LOADING:
           return {
            ...state,
            loading:action.isLoading
        }
    
        case VIDEO_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data:action.Video,
                loading:false,
            }

        default:
            return state;
    }
}
