import { EPAPER_MENU_FETCH_DATA_SUCCESS, EPAPER_MENU_HAVE_ERROR, EPAPER_MENU_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function ePaperMenuAction(action, payload) {
    switch (action) {
        case EPAPER_MENU_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };
    
        case EPAPER_MENU_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };
    
        case EPAPER_MENU_FETCH_DATA_SUCCESS:
            return {
                type: action,
                ePaperMenu: payload,
            };

        default:
            return;
    }
}

export function ePaperMenuFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(ePaperMenuAction(EPAPER_MENU_ARE_LOADING, true));
                Get(HttpService.ePaperMenu).then((result) => {
                    dispatch(ePaperMenuAction(EPAPER_MENU_FETCH_DATA_SUCCESS, result));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(ePaperMenuAction(EPAPER_MENU_HAVE_ERROR, true));
        }
    }
}
